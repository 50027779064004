import React, {Component} from "react";
import Carousel from 'react-bootstrap/Carousel'
import bg_1 from '../../img/bg_1.png';
import bg_2 from '../../img/bg_2.png';

class SliderComponent extends Component {
    render() {
        return (
            <div id="Home">
                <Carousel variant="dark">
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={bg_1}
                            alt="First slide"
                        />
                        {/*<Carousel.Caption>*/}
                        {/*    <h5>First slide label</h5>*/}
                        {/*    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                        {/*</Carousel.Caption>*/}
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={bg_2}
                            alt="Second slide"
                        />
                        {/*<Carousel.Caption>*/}
                        {/*    <h5>Second slide label</h5>*/}
                        {/*    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                        {/*</Carousel.Caption>*/}
                    </Carousel.Item>
                </Carousel>
            </div>
        )
    }
}

export default SliderComponent;