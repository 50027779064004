import React,{Component} from 'react';
import {Navbar,NavDropdown,Nav,Container} from 'react-bootstrap';
import logo from '../../logo.svg';
import './Navbar.style.css'

class NavbarComponent extends Component {
    render() {
        return (
            <div>
                {/*<nav className="navbar navbar-light bg-secondary">*/}
                {/*    <div className="container">*/}
                {/*        <a className="navbar-brand" href="#">*/}
                {/*            <img src={logo} alt="" width="300" height="100"/>*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*</nav>*/}
                <Navbar  collapseOnSelect expand="lg" bg="secondary" variant="dark">
                    <Container>
                        <Navbar.Brand href="#home">
                            <img src={logo} alt="" width="300" height="100"/>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            {/*below line separates logo and rest of the tabs in navbar*/}
                            <Nav className="me-auto"></Nav>
                            <Nav>
                                <Nav.Link href="#Home">HOME</Nav.Link>
                                <Nav.Link href="#AboutUs">ABOUT US</Nav.Link>
                                {/*<NavDropdown title="CATALOGUES" id="collasible-nav-dropdown">*/}
                                {/*    <NavDropdown.Item href="#action/3.1">CATALOGUE1</NavDropdown.Item>*/}
                                {/*    <NavDropdown.Item href="#action/3.2">CATALOGUE1</NavDropdown.Item>*/}
                                {/*    <NavDropdown.Item href="#action/3.3">CATALOGUE1</NavDropdown.Item>*/}
                                {/*    <NavDropdown.Divider />*/}
                                {/*    <NavDropdown.Item href="#action/3.4">CATALOGUE1</NavDropdown.Item>*/}
                                {/*</NavDropdown>*/}
                                <Nav.Link href="https://wa.me/%2B919595770692?text=Hello!%0AI%20have%20a%20query">WHATSAPP</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        )
    }
}
export default NavbarComponent;