import React, {Component} from 'react';
// import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.css';
// Put any other imports below so that CSS from your
// components takes precedence over default styles.
import './App.css';
import HomePage from './pages/homepage/homepage.component';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            string: 'Darshan'
        }
    }
  render(){
      return (
          <div className="App">
              <HomePage/>
          </div>
      );
  }
}

export default App;

//rsuite is added using "yarn add rsuite"
//refer to below documentation for css of rsuite and modification of styles
//https://rsuitejs.com/guide/use-with-create-react-app/