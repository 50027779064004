import React from 'react';
import './homepage.styles.scss';
import NavbarComponent from '../../components/Navbar/Navbar.component';
import AboutUsComponent from "../../components/AboutUs/AboutUs.component";
import SliderComponent from "../../components/Slider/slider.component";
import Copyright from "../../components/copyright/copyright.component";
import MapComponent from "./../../components/Map/map.component"

const HomePage = () => (
    <div className='Homepage'>
        <NavbarComponent id="Home"/>
        <SliderComponent/>
        <AboutUsComponent />
        <MapComponent/>
        <Copyright/>
    </div>
)

export default HomePage;

