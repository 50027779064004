import React, {Component} from 'react';

class AboutUsComponent extends Component {
    render() {
        return (
            <div className='container'>
                <h1 className="text-center" id="AboutUs">About Us</h1>
                <hr/>
                <p>Darshan Enterprise is a house hold name in the field of electrical supply in the Aurangabad since 2008.
                    We arrange all kinds of electrical needs for our customers.
                    Mainly we lend our expertise and provide services in the MIDC areas near by Aurangabad.
                </p>
                {/*<h1 className="text-center">Conract Us</h1>*/}
                {/*<hr/>*/}
                <h3 >Contact Us <br/></h3>
                    <div>
                        <p><strong>Call(sales):</strong> <a href="callto:+919595770692">9595 77 0692</a></p>
                        <p><strong>Call(purchase):</strong> <a href="callto:+918454845404">8454 8454 04</a></p>
                        <p><strong>Email: </strong><a href="mailto:sales@darshanent.co.in">sales@darshanent.co.in</a></p>
                        <p><strong>Address:</strong> Shop no: D-9, C252/3, Divakar Udyog Bhavan,
                            MIDC Area Waluj, Aurangabad, 431 136.</p>
                        <hr/>
                    </div>

            </div>
        )
    }
}

export default AboutUsComponent;