import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';


export default function Copyright() {
    return (
      <div >
          {/*change backgrount color of this div*/}
          <Typography variant="body2" color="textSecondary" align="center">
              {'Copyright © '}
              <Link color="inherit" href="https://darshanent.co.in">
                  Darshan Enterprises
              </Link>{' '}
              {new Date().getFullYear()}
              {'.'}
          </Typography>
      </div>
    );
  }