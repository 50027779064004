// import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import React, {Component} from "react";

class MapComponent extends Component {
    render() {
        return (
            <div className="google-map-code container">
                <h3 >Reach Us <br/></h3>
                <hr/>
                <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30024.383233635963!2d75.2300278395508!3d19.837925900000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdb99626fd942d9%3A0xd43270cd9316b8f!2sDarshan%20ENTERPRISES!5e0!3m2!1sen!2sin!4v1627546415189!5m2!1sen!2sin"
    width="100%" height="450" loading="eager"/>
                <hr/>
            </div>
        )
    }
}
export default MapComponent;